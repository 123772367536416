@font-face {
    font-family: "Celias";
    src: local("Celias"), url(../fonts/Celias/Celias.ttf) format("truetype");
}

@font-face {
    font-family: "Celias-Light";
    src: local("Celias-Light"), url(../fonts/Celias/Celias-Light.ttf) format("truetype");
}

@font-face {
    font-family: "Celias-Medium";
    src: local("Celias-Medium"),
        url(../fonts/Celias/Celias-Medium.ttf) format("truetype");
}

@font-face {
    font-family: "Roboto-Medium";
    src: local("Roboto-Medium"), url(../fonts/Roboto/Roboto-Medium.ttf) format("truetype");
}

@font-face {
    font-family: "Roboto-Light, sans-serif";
    src: local("Roboto-Light"), url(../fonts/Roboto/Roboto-Light.ttf) format("truetype");
}


@font-face {
    font-family: "Roboto";
    src: local("Roboto-Medium"), url(../fonts/Roboto/Roboto-Medium.ttf) format("truetype");
}


@font-face {
    font-family: 'Roboto';
    src: local("Roboto-Regular"), url(../fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}


@font-face {
    font-family: 'Roboto', sans-serif;
    src: local("Roboto-Bold"), url(../fonts/Roboto/Roboto-Bold.ttf) format("truetype");
}

:root {
    /* --prime: #3a24c7; */
    --prime: #222647;
    --prime2: #383d6e;
    --prime3: #000026;
    --prime4: #0d6efd;
    /* --color1: var(--color1); */
    /* --color1: #170f56; */
    /* --color1: #052659; */
    --color1: rgb(0, 87, 255);
    --color2: #00845d;
    --color3: #ae1519;
    --color4: #055e39;
    --color5: #6386a0;
    --color6: #0039ad;
    --color7: #00339c;
}

.overflowY {
    overflow-y: scroll;
    max-height: 600px;
    scrollbar-width: none !important;
}
.bank-img {
    width: 20px;
    height: 20px;
}
.cart-deli-img {
    width: 25px;
    height: 25px;
}
.img-opacity {
    filter: opacity(0.3);
}
/* Modal Backdrop fix */
.green-button {
    background-color: rgb(78, 221, 78);
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 8px;
    cursor: pointer;
    margin-right: 5px;
}

.cancel-button {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 8px;
    margin-right: 5px;


    cursor: pointer;
}
.w-10px {
    width: 10px;
}
.product-timing-location {
    border: none !important;
    font-size: 12px;
    color: #3e3e3e;
    font-weight: 600;
}

.cover_img {
    object-fit: contain;
    width: 100%;
    height: 100px;
}

#root>div,
#root>div>div {
    z-index: unset;
}

/*  */
/* html,body {
    position: fixed;
    height: 100%;
    overflow: hidden;
    width: 100%;
} */

body {
    font-family: "Celias";
    background-color: #ececec  !important;
    font-weight: 400;
    max-width: 100%;
    overflow-x: hidden !important;
    letter-spacing: 0.4px;
    font-size: 13px;
    line-height: 1.4;
    color: #000;
}

:root {
    --prime: #A80032;
}

.logo-img {
    width: 20px;
    height: 20px;
}

.page-body {
    /* display: flex; */
    display: -ms-flexbox;
    /* overflow-x: hidden; */
}

.page-content {
    /* width: 100%; */
    /* margin-top: 50px; */
}

.page-content-map {
    width: 100%;
}

.page-content-home {
    width: 100%;
    margin-top: 50px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Celias-Medium" !important;
    color: #000 !important;
    letter-spacing: 0.3px !important;
    font-weight: 400 !important;
}

h6 {
    font-size: 16px;
}

p {
    color: #777;
    font-size: 12px;
    margin-bottom: 0;
}

small {
    color: #aaa;
    font-weight: 600;
    font-size: 11px !important;
    letter-spacing: 0.4px;
}

.extra-small {
    font-size: 10px;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

label {
    color: #888;
}

.form-label {
    color: #555454 !important;
    font-size: 13px !important;
}

.text-dark {
    color: #000 !important;
}

.text-black {
    color: #3c3c3c !important;
}

.text-green {
    color: green !important;
}

.text-red {
    color: #d30000;
}

.text-blue {
    color: blue;
}

.text-prime {
    color: var(--prime) !important;
}

.text-gray {
    color: gray !important;
}

.text-orange {
    color: orange;
}

.btn {
    font-family: "Celias";
}

.btn-prime {
    background-color: var(--prime) !important;
    color: #fff !important;
    font-weight: 500 !important;
}

.btn-outline-prime {
    border-color: var(--prime) !important;
    color: var(--prime) !important;
}

.btn-outline-prime:hover {
    border-color: var(--prime) !important;
    background-color: var(--prime) !important;
    color: #fff !important;
}

.btn-green {
    background-color: green !important;
    color: #fff !important;
    font-weight: 500 !important;
}

.btn-blue {
    background-color: #4442e9;
    color: #fff;
}

.btn-orange {
    background-color: #ffaf36;
    color: #fff;
}

.bg-fff {
    background-color: #fff !important;
}



.bg-prime {
    background-color: var(--prime);
}

.list:hover {
    background: rgb(222, 233, 251) !important;
    border-radius: 3px;
}

.head-bar {
    background: #fff !important;
    padding: 14px 20px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    position: sticky;
    top: 50px;
    z-index: 10;
}
.head-bar-order {
    background: #fff !important;
    padding: 14px 20px 0;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    position: sticky;
    top: 50px;
    z-index: 10;
}

.card {
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 6px;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.end {
    display: flex;
    align-items: center;
    justify-content: end;
}

.btn-primary-outline {
    border: 1px solid #0d6efd;
    border-radius: 5px;
    color: #0d6efd;
    background-color: #fff;
}

.btn-primary-outline:hover {
    color: #fff;
    background-color: #0d6efd;
}

.btn-secondary-outline {
    border: 1px solid #aaa;
    background: #fff;
    color: #222;
}

.MuiSlider-root {
    color: var(--prime) !important;
    cursor: pointer;
}

.form-check-input:checked {
    background-color: var(--prime) !important;
    border-color: var(--prime) !important;
}

/* Sidebar css */

.sidebar {
    color: #000;
    width: 100%;
    background: #fff;
    transition: margin-left 0.3s ease;
    box-shadow: 0px 1px 5px #ccc;
    position: fixed;
    z-index: 17;
    bottom: 0;
    height: 97%;
    overflow: auto;
}

.sidebar-options {
    display: flex !important;
    justify-content: end;
    align-items: center;
}

.sidebar-footer {
    margin-top: 40px;
    padding: 0 10px;
    margin-bottom: 40px;
}

.sidebar-sticky {
    /* width: 100%; */
    /* margin-left: 15px;
    margin-right: 15px; */
    /* overflow-y: auto; */
    /* height: calc(100vh - 350px); */
    /* scrollbar-width: none !important; */
}

.sidebar-header {
    position: sticky;
    padding: 10px;
}

.sidebar-brand {
    height: 90px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 500;
    color: #555 !important;
}

.sidebar-brand img {
    max-height: 90px;
}

.page-sidebar {
    display: none;
}

.sidebar-sticky ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar-sticky ul li a {
    margin-bottom: 5px;
    padding: 10px;
    display: block;
    text-decoration: none;
    color: #333 !important;
    font-size: 12px;
    font-weight: 500 !important;
    /* font-family: "poppins-medium"; */
    letter-spacing: 0.8px;
    /* text-transform: uppercase; */
    /* border-bottom: 1px solid #eee; */
}

.sidebar-sticky>ul>li:first-child>a {
    /* border-top: 1px solid #eee; */
}

.sidebar-sticky ul>li>a.active~.sidebar-dropdown-menu ul>li>a {
    /* background: #f8f8f8; */
}

.sidebar-sticky ul>li>a:hover,
.sidebar-sticky ul>li>a.active {
    /* background: #fddfec !important; */
    color: var(--prime) !important;
    /* border-right: 3px solid var(--prime) !important; */
    font-weight: 600 !important;
}

.sidebar-sticky ul>li>a:hover div,
.sidebar-sticky ul>li>a.active div {
    color: var(--prime) !important;
}

.sidebar-sticky ul li a p {
    font-weight: 600 !important;
    /* font-family: "poppins-medium"; */
    color: #dfdfdf;
    font-size: 12px !important;
    letter-spacing: 0.8px !important;
}

.sidebar-sticky ul>li>a>i {
    margin-right: 6px;
    color: #fff;
    width: 20px;
    text-align: center;
}

.sidebar-sticky ul>li>a>img {
    height: 22px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
}

.sidebar-sticky ul>li>a>span {}

.sidebar-dropdown .sidebar-dropdown-menu {
    display: none;
    padding-top: 3px;
}

.sidebar-dropdown .sidebar-dropdown-menu ul li a {
    padding-left: 30px;
}

.sidebar-dropdown .sidebar-dropdown-btn.active~.sidebar-dropdown-menu {
    display: block;
}

.sidebar-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    padding: 10px;
    z-index: 1301;
    box-shadow: none !important;
}

.sidebar-close span:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 25%;
    width: 100%;
    height: 100%;
    border-left: 2px solid #888;
    transform: rotate(45deg);
}

.sidebar-close span:after {
    content: "";
    position: absolute;
    top: 50%;
    left: -40%;
    width: 100%;
    height: 100%;
    border-right: 2px solid #888;
    transform: rotate(-45deg);
}

.sidebar-dropdown-arrow {
    float: right;
    margin-top: 2px;
}

.sidebar-toggler {
    position: relative;
    outline: 0 !important;
    box-shadow: none !important;
    border: 0;
    padding: 0 0 0 10px;
}

.sidebar-toggler span {
    width: 22px;
    height: 2px;
    background-color: #333;
    margin: 4px 0;
    display: block;
}

.sidebar-username {
    text-decoration: none !important;
    color: #6c757d !important;
}

.sidebar-options>a,
.sidebar-options>button {
    background: transparent;
    border: 0;
    outline: 0;
    text-decoration: none !important;
    color: #fff;
    margin-top: 5px;
    font-size: 16px;
    padding: 10px;
    border-radius: 10px;
}

.sidebar-options>a:hover,
.sidebar-options>button:hover {
    background: rgba(255, 255, 255, 0.2);
}

/*  */
.delivery {
    width: 25px;
    height: 25px;
}

/* Navbar css */

.navbar-custom {
    background: #fff;
    height: 50px;
    border-bottom: 1px solid #f3f3f3;
    z-index: 99;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.navbar-custom .logo-img {
    max-height: 35px;
    max-width: 200px;
}

.navbar-custom-new {
    background: #fff;
    height: 50px;
    border-bottom: 1px solid #f3f3f3;
    z-index: 99;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.navbar-custom-new .logo-img {
    max-height: 35px;
    max-width: 200px;
}

.navbar-custom h5 {
    color: var(--prime) !important;
}

.navbar-custom {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 0px;
}

.navbar-custom .nav-link {
    padding: 15px 10px;
    color: #fff;
    font-weight: 500;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-custom .nav-link>img {
    width: 28px;
}

.navbar-custom-new {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 0px;
}

.navbar-custom-new .nav-link {
    padding: 15px 10px;
    color: #fff;
    font-weight: 500;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-custom-new .nav-link>img {
    width: 28px;
}

.navbar-search>.dropdown {
    width: 100%;
}

/* .navbar-profile {
    color: #fff;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 20px;
}

.navbar-profile-sm {
    max-width: 100px;
    max-height: 100px;
    border-radius: 100%;
    margin-bottom: 5px;
} */

.navbar-profile {
    color: #fff;
    text-align: center;
    /* padding: 20px 0px; */
    background-color: #fff;
    position: sticky;
    top: 0px;
}

.navbar-profile-sm {
    max-width: 100px;
    max-height: 100px;
    border-radius: 100%;
    margin-bottom: 5px;
}

/*  */
.add_img {
    object-fit: contain;
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.img-ss {
    height: 120px;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
    border: 1px solid #eee;
}

.scroll-auto {
    overflow: auto;
}

.h-300 {
    height: 300px;
}

/* Modal css */

.modal-header {
    border-bottom: none !important;
}

.modal-content {
    border-radius: 0px !important;
}

.modal-footer {
    border-top: none !important;
}

.modal-dialog {
    /* height: 100vh; */
}

.modal {
    padding: 0px !important;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    padding: 2px 16px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    /* max-height: 500px; */
}
.map-modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    padding: 2px 16px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    /* max-height: 500px; */
}

.payment-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
    /* Adjust the margin as needed */
}

.payment-column {
    flex: 1;
    margin-right: 10px;
    /* Adjust the margin as needed */
}


.heading {
    font-weight: bold;
    height: 30px;
    font-size: 12px;
}

/* .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .modal-content {
    height: auto;
    width: 100%;

    min-height: 100%;
    border-radius: 0;
  } */

/*  */

a {
    text-decoration: none !important;
    color: #000;
}

.btn {
    font-size: 13px;
    letter-spacing: 0.4px;
    border-radius: 6px;
    padding-left: 5px;
    padding: 6px 12px;
    box-shadow: none !important;
}

.btn-sm {
    font-size: 11px;
    /* font-weight: 600; */
}

.text-shrink {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.toast.notification {
    cursor: pointer;
    border: 0;
    position: fixed;
    z-index: 1200;
    bottom: 20px;
    left: 50%;
    margin-left: -175px;
    width: 100%;
    background: #333;
    color: #fff;
}

.dropdown-menu {
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 7px 29px 0px;
    border: none !important;
    z-index: 1 !important;
    padding: 5px;
    border-radius: 6px;
}

.form-control,
.form-select {
    font-size: 12px;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border-radius: 9px;
}

.form-control:hover,
.form-select:hover {
    border-color: #4d57eb;
}

.form-control:focus,
.form-select:focus {
    border-color: none !important;
    box-shadow: none !important;
}

.input-group-text {
    font-size: 14px;
}

.cursor {
    cursor: pointer;
}

.pagination\:container {
    display: flex;
    align-items: center;
    justify-content: end;
}

.arrow\:text {
    display: block;
    vertical-align: middle;
    font-size: 13px;
    vertical-align: middle;
}

.pagination\:number {
    --size: 32px;
    --margin: 6px;
    margin: 0 var(--margin);
    border-radius: 6px;
    background: #e1e0e0;
    max-width: auto;
    min-width: var(--size);
    height: var(--size);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 6px;
}

.pagination\:number:hover {
    background: lighten(#202020, 3%);
}

.pagination\:number.active {
    background: lighten(#202020, 3%) !important;
}

.pagination\:active {
    background: #4891ff !important;
    color: #fff !important;
    position: relative;
}

.btn-blue-outline {
    border: 1px solid #0d6efd;
    border-radius: 6px;
    color: #0d6efd;
    font-size: 12px;
    text-align: center;
    padding: 5px;
}

.btn-red-outline {
    border: 1px solid #d30000;
    border-radius: 6px;
    color: #d30000;
    font-size: 12px;
    text-align: center;
    padding: 5px;
}

.btn-orange-outline {
    border: 1px solid #e78300;
    border-radius: 6px;
    color: #e78300;
    font-size: 12px;
    text-align: center;
    padding: 5px;
}

.tabs {
    display: flex;
}

.tabs .tabs-head {
    padding: 0px 10px;
    cursor: pointer;
}

.tabs .tabs-head p {
    font-size: 13px;
    padding: 6px 10px;
}

.active_tab p {
    color: var(--prime);
    font-weight: 600 !important;
    background: #fddfec;
    border-radius: 20px;
}

.f_tab {
    cursor: pointer;
}

.accordion-button:focus {
    z-index: 3;
    border-color: #dddddd !important;
    outline: 0;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: #f9f9f9 !important;
}

.accordion-button {
    padding: 1rem !important;
}

.accordion-button::after {
    width: 1.2rem !important;
    height: 1.2rem !important;
    background-size: 1.2rem !important;
}

.accordion-item {
    background-color: #fff;
    border: none !important;
}

.accordion-item:last-of-type .accordion-collapse {
    margin-top: 10px;
}

.accordion-body {
    padding: 0 !important;
}

.product-img {
    height: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
}

.MuiDrawer-paperAnchorBottom {
    /* min-height: 120px; */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.MuiDrawer-paperAnchorBottom ul {
    list-style: none !important;
}

.MuiAccordion-root::before {
    background-color: transparent !important;
}

.MuiDrawer-paperAnchorRight {
    width: 30%;
}

.MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0 !important;
}

.MuiAccordionSummary-expandIcon {
    transform: rotate(0deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.scrollbar-none {
    scrollbar-Width: none !important;
}

.w-100 {
    width: 100%;
}

.w-80 {
    width: 80%;
}

.w-20 {
    width: 20%;
}

.w-70 {
    width: 70% !important;
}

.w-30 {
    width: 30% !important;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: auto !important;
}


.css-b62m3t-container {
    z-index: 9;
}

.Price {
    z-index: 8 !important;
}

.bottom-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45px;
    z-index: 1;
}

.rs-table-hover .rs-table-row:hover {
    background-color: #e0f4fa !important;
}

.para-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.disc-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 80px;
}

.select__control,
.select__menu {
    text-align: left !important;
}

.dropdown-toggle::after {
    margin-left: 0 !important;
    content: none !important;
}

.scrollY70 {
    overflow-y: scroll !important;
    min-height: 40vh;
    max-height: 70vh !important;
    overflow-x: hidden !important;
    scrollbar-width: none;
}

.create_acc {
    width: 100%;
    height: 20vh;
    margin-bottom: 20px;
}

.inherit {
    width: inherit;
}

.bottom-tabs {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 45px;
    /* z-index: 1; */
    z-index: 1600;
    background: #fff;
}

/* .bg-store{
    background-color: #ecfdec;
    border-radius: 5px;
} */

/* Media quries */
.scroll-container {
    height: 400px;
    overflow-y: auto;
  }
@media(min-width:768px) {
    .out_of_stock_swipe .react-swipeable-view-container {
        box-shadow: none !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .out_of_stock_swipe .react-swipeable-view-container>div{
        width: 50% !important;
    }

    .chart-container {
        width: 400px;
        height: 400px;
        margin: 0 auto;
      }
      
    .card-lg {
        border: none !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        border-radius: 6px;
        background-color: white;
    }
    .row-lg
        {
            --bs-gutter-x: 1.5rem;
            --bs-gutter-y: 0;
            display: flex;
            flex-wrap: wrap;
            margin-top: calc(-1* var(--bs-gutter-y));
            margin-right: calc(-0.5* var(--bs-gutter-x));
            margin-left: calc(-0.5* var(--bs-gutter-x));
        }
    .container-lg{

        width: 100%;
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
        margin-right: auto;
        margin-left: auto;
    }
    .w-lg-50 {
        width: 50% !important;
    }
    .d-flex-lg{
        display:flex;
    }
    .md-text-end {
        text-align: right !important;
    }

    .d-md-block {
        display: block;
    }

    .md-ps-0 {
        padding-left: 0 !important;
    }

    .md-px-3 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .md-ps-3 {
        padding-left: 1.5rem;
    }

    .md-pe-3 {
        padding-right: 1.5rem;
    }

    .bottom {
        bottom: 0px;
        position: fixed;
        width: 30%;
    }

    .md-w-50 {
        width: 50% !important;
    }

    .timeline-icon {
        position: absolute;
        z-index: 14;
        left: 14.4% !important;
        padding-top: 5px;
    }

    .navfooter {
        display: none;
    }

    .page-content {
        margin-left: 220px;
    }



}

/* Skeleton timeline start */


.delivery_status_vertical-skeleton  li {
    display: flex;
    color: #999;
}

.delivery_status_vertical-skeleton time {
    position: relative;
    padding: 0 1em 1.5em 0em;
    font-size: 9px;
    width: 23%;
    color: #444;
    letter-spacing: 0.5px;
    font-family: "Celias";
}

.delivery_status_vertical-skeleton time::after {
    content: "";
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    transform: translateX(50%);
    border-radius: 50%;
    background: #bbb;
    border: 2px #bbb solid;
    width: 10px;
    height: 10px;
}

.delivery_status_vertical-skeleton .timeline {
    padding: 0 1em 2em 1em;
    position: relative;
    /* font-size: 12px; */
    width: 80%;
    /* color: #444; */
    letter-spacing: 0.4px;
    font-family: "Celias";
}

.delivery_status_vertical-skeleton .timeline::before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    height: 100%;
    border-left: 1px #cccccc solid;
}

.delivery_status_vertical-skeleton strong {
    /* display: block; */
    font-weight: bold;
    color: #555;
}

.delivery_status_vertical-skeleton {
    margin: 1em 0;
    width: 100%;
    padding: 0;
    position: relative;
}

.delivery_status_vertical-skeleton,
.delivery_status_vertical-skeleton *::before,
.delivery_status_vertical-skeleton *::after {
    box-sizing: border-box;
    font-family: arial;
}


/* Skeleton timeline end */

.delivery_status_vertical li {
    display: flex;
    color: #999;
}

.delivery_status_vertical time {
    position: relative;
    padding: 0 1em 1.5em 0em;
    font-size: 9px;
    width: 23%;
    color: #444;
    letter-spacing: 0.5px;
    font-family: "Celias";
}

.delivery_status_vertical time::after {
    content: "";
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    transform: translateX(50%);
    border-radius: 50%;
    background: #18e807;
    border: 2px #18e807 solid;
    width: 10px;
    height: 10px;
}

.delivery_status_vertical .timeline {
    padding: 0 1em 2em 1em;
    position: relative;
    /* font-size: 12px; */
    width: 80%;
    /* color: #444; */
    letter-spacing: 0.4px;
    font-family: "Celias";
}

.delivery_status_vertical .timeline::before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    height: 100%;
    border-left: 1px #cccccc solid;
}

.delivery_status_vertical strong {
    /* display: block; */
    font-weight: bold;
    color: #555;
}

.delivery_status_vertical {
    margin: 1em 0;
    width: 100%;
    padding: 0;
    position: relative;
}

.delivery_status_vertical,
.delivery_status_vertical *::before,
.delivery_status_vertical *::after {
    box-sizing: border-box;
    font-family: arial;
}

.timeline-icon {
    position: absolute;
    z-index: 14;
    left: 23%;
    padding-top: 5px;
}

ul.timeline {
    list-style-type: none;
    position: relative;
}

ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}

ul.timeline>li {
    margin: 20px 0;
    padding-left: 20px;
}

ul.timeline>li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}

.user-img-timeline {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.page-main {
    width: 100%;
    margin-top: 50px;
}

.page-main section {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
}

.page-main section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.25);
}

.position-sticky-top {
    position: sticky;
    top: 0px;
    z-index: 9;
}

@media(max-width:768px) {
    .small-card{
    margin-bottom:40px;
    }

    .search-order{
width: 40%;
    }
    .sm-screen{
        margin-top: 50px!important;
    }
    .pad-top {
        padding-top: 40px;
    }

    .modal-dialog {
        max-width: 100% !important;
        margin: 0px !important;
    }

    .sm-p-0 {
        padding: 0px !important;
    }

    .sm-pb-0 {
        padding-bottom: 0px !important;
    }

    .sm-pb-1 {
        padding-bottom: 0.5rem !important;
    }

    .sm-pt-2 {
        padding-top: 1rem;
    }

    .sm-pt-4 {
        padding-top: 2rem;
    }

    .sm-px-0 {
        padding-left: 0 !important;
        padding-right: 0px !important;
    }

    .sm-px-2 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .sm-pe-1 {
        padding-right: 0.5rem !important;
    }

    .sm-pb-2 {
        padding-bottom: 1rem !important;
    }

    .sm-ps-1 {
        padding-left: 0.5rem !important;
    }

    .sm-px-1 {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }

    .sm-px-2 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .sm-py-2 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .sm-py-1 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    .sm-mt-0 {
        margin-top: 0 !important;
    }

    .sm-mt-2 {
        margin-top: 0.5rem !important;
    }

    .sm-mt-3 {
        margin-top: 1rem !important;
    }

    .sm-mt-4 {
        margin-top: 1.5rem !important;
    }

    .sm-mt-5 {
        margin-top: 2rem !important;
    }

    .sm-mt-6 {
        margin-top: 2.5rem !important;
    }

    .sm-border-right-none {
        border-right: none !important;
    }

    .d-md-none {
        display: none !important;
    }

    .d-sm-none {
        display: none;
    }

    .d-sm-block {
        display: block !important;
    }

    .sm-mb-2 {
        margin-bottom: 0.5rem;
    }

    .bottom {
        bottom: 0px;
        position: fixed;
        width: 100%;
    }

    .page-content {
        /* margin-left: 220px; */
        margin-top: 9vh !important;
    }

    .sm-w-100 {
        width: 100% !important;
    }

    .sidebar.active {

        height: 100vh;
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;
        display: block;
        transition-property: top, left;
        transition-duration: 1s, 1s;
        transition-delay: 0s, 1s;
        z-index: 1400;
        background-color: rgb(0, 0, 0, 0.3) !important;
    }

    .sidebar.active .sidebar-w {
        /* width: calc(100% - 20%); */
        background-color: #fff !important;
        height: 100%;
    }

    .map-height {
        height: 90vh !important;
    }

    .map-swipe-sheet p {
        font-size: 11px;
    }

    .sm-fs-10 {
        font-size: 10px !important;
    }

    .sm-fs-9 {
        font-size: 9px !important;
    }

    .sm-h-45 {
        height: 45px !important;
    }

    .category-img {
        width: 25px;
        height: 25px;
        object-fit: contain;
    }

    .category-img-child{
        width: 25px;
        height: 25px;
        margin-left: 20px !important; 
    }
    
}

@media (min-width: 768px) {
    .map-height {
        height: calc(100vh - 50px) !important;
    }

    .md-block {
        display: block !important;
    }

    .category-img {
        width: 40px;
        height: 40px;
        object-fit: contain;
    }
    .category-img-child{
        width: 25px;
        height: 25px;
        margin-left: 20px !important; 
    }
    
}


@media (max-width: 991px) {
    .page-content {
        margin-top: 9vh !important;
    }

    .sidebar {
        transition: 6.5s ease-in-out;
        display: none;
    }

    .sidebar.active {
        position: fixed;
        right: 0;
        top: 0;
        height: 100vh;
        /* width: calc(100% - 25%); */
        display: block;
        transition-property: top, left;
        transition-duration: 1s, 1s;
        transition-delay: 0s, 1s;
    }

    .sidebar-footer {
        margin-top: 40px;
        margin-bottom: 0;
    }

    .navbar-search {
        display: none;
    }

    .navbar-search.active {
        position: absolute;
        left: 10px;
        right: 10px;
        background: #fff;
        z-index: 111111;
        top: 0;
        height: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
    }

    .navbar-search.active>.dropdown {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media (min-width: 992px) {
    .page-sidebar {
        width: 27%;
    }

    .page-content {
        margin-left: 220px;
    }

    .navbar-search {
        width: 25%;
    }

    .navbar-custom .logo-img {
        max-height: 35px;
        width: auto;
        max-width: 200px;
    }

    .navbar-custom-new .logo-img {
        max-height: 35px;
        width: auto;
        max-width: 200px;
    }

    .navbar-brand.navlogo {
        width: 17%;
        text-align: center;
    }


    .sidebar-sticky::-webkit-scrollbar {
        width: 5px;
        height: 4px;
    }

    .sidebar-sticky::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.2);
    }

    .sidebar-sticky::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.3);
    }

    .sidebar-sticky::-webkit-scrollbar-thumb:hover {
        background: rgba(255, 255, 255, 0.4);
    }

    .sidebar-sticky {
        scrollbar-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.2);
        scrollbar-width: thin;
    }

    .sidebar {
        margin-left: 0;
        width: 220px;
        top: 50px;
    }

    .sidebar .sidebar-header .sidebar-close {
        display: none;
    }
}

.navfooter .col-2 {
    flex: 0 0 20% !important;
    max-width: 20% !important;
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center;
}

.bottom-tabs {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    /* padding: 5px 0; */
    /* z-index: 1; */
    background: #fff;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    box-shadow: 2px 0px 6px #ccc;
    padding: 5px 0px;
}

.navfooter {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 1500;
    border-top: 1px solid #eee;
    align-items: center;
    text-align: center;
}



#map-test {
    height: 100%;
    position: relative;
    border-radius: 6px;
}

.map_class {
    width: 100%;
    overflow: none !important;
}

.map_side {
    overflow: scroll;
    height: 90vh;
    scrollbar-width: none !important;
}

.map_side::-webkit-scrollbar {
    display: none !important;
}

.color-dot-blue {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #4442e9;
    border-radius: 50%;
}

.color-dot-red {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #4cd06d;
    border-radius: 50%;
    background-color: red;
    transition: opacity 1s ease-in-out;
}

.color-dot-red {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #4cd06d;
    border-radius: 50%;
    background-color: red;
    transition: opacity 1s ease-in-out;
}

.color-dot-yellow {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #4cd06d;
    border-radius: 50%;
    background-color: yellow;
    animation: 1s blink infinite;
}

.color-dot-green {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #4cd06d;
    border-radius: 50%;
    background-color: green;
    animation: 1s blink infinite;
}

.gallery_img {
    width: 100%;
    height: 140px;
    object-fit: contain;
    background-color: #fff4f8;
    border-radius: 5px;
}

.position-check {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 13px;
    width: 13px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.position-check-left {

    position: absolute;
    top: 3px;
    left: 5px;
}

.card-border {
    border: 2px solid var(--prime) !important;
}

.search-bar {
    width: 100%;
}

.search-bar {
    border: none !important;
    letter-spacing: 0.2;
    background-color: none !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.tab-active {
    color: #e80464 !important;
    border-bottom: 2px solid #e80464;
}


.search-bar .form-control {
    --input-bg: var(--secondary-color-lighter);
    width: 100%;
    border-color: transparent;
    border-radius: 15px;
}

.search-bar .btn {
    --btn-bg: var(--secondary-color-lighter);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 6px 18px 18px;
    border-color: transparent;
    border-radius: 15px;
}


.input-icons {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 10px;
    /* background-color: #005d86; */
    width: 100%;
    height: 36px;
    align-items: center;
}

.input-icons input {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    height: 30px;
}

.Dropzone {
    height: 130px;
    /* width: 50%; */
    background-color: #fff;
    /* border: 2px dashed rgb(187, 186, 186); */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    margin: 0px auto;
    border: 1px dashed #888;
}

.dropzone_img {
    object-fit: cover;
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
    border-radius: 5px;
}

.FileInputNone {
    display: none;
}

.category-list {
    margin-left: 20px;
    /* Adjust the margin as needed */
}

.category-item {
    margin-bottom: 5px;
    /* Adjust the margin as needed */
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-text {
    width: 100%;
    height: 0.7rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}

.skeleton-text__body {
    width: 75%;
}

.skeleton-footer {
    width: 30%;
}


.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-text {
    width: 100%;
    height: 0.7rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}

.skeleton-text__body {
    width: 75%;
}

.skeleton-footer {
    width: 30%;
}

.MuiAccordionDetails-root {
    display: block !important;
    padding: 8px 16px 16px;
}

.wrap-data {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

.icon-w20 {
    width: 20px;
    height: 20px;
}

.h-30 {
    height: 30px;
}

.h-35 {
    height: 35px;
}

.radius {
    border-radius: 8px;
}

input::placeholder {
    font-size: 12px !important;
    letter-spacing: 0.5px;
}

.fs-11px {
    font-size: 11px;
}
.fs-10px {
    font-size: 10px;
}

.border-right {
    border-right: 1px solid #ccc;
}

.border-left {
    border-left: 1px solid #ccc;
}

.profile-round-img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}


.bottom-sheet-hr {
    height: 3px;
    border-radius: 6px;
    width: 50px;
    background-color: #aaaaaa;
}

.border-top-radius {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.ReactSwipeableBottomSheet--open, .ReactSwipeableBottomSheet--closed {
    background-color: #e0e0e0 !important;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    overflow-x: hidden !important;
}


.fs-7 {
    font-size: 13px !important;
}

.active-shadow-layout {
    background-color: rgb(0 0 0 / 41%) !important;
    filter: blur(1px);
    -webkit-filter: blur(1px);
}

.overlay-card {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    justify-content: center;
    align-items: center;
}

.position-sticky-top-50px {
    position: sticky;
    top: 126px;
    z-index: 9;
}

.overflowY-90vh {
    overflow-y: scroll;
    height: 90vh;
    scrollbar-width: 0px !important;
    --webkit-scrollbar: 0px !important;
}

.overflowY-90vh::-webkit-scrollbar {
    display: none !important;
    scrollbar-width: none !important;
}

.border-bottom-prime {
    border-bottom: 1px solid var(--prime);

}

.header-tap {
    border-bottom: 2px solid var(--prime);
    border-radius: 0 !important;
    box-shadow: none !important;
    color: var(--prime) !important;
    font-weight: 600;
}

.radius-none {
    border-radius: 0 !important;
}

.delivery_profile_img {
    width: 100%;
    height: 70px;
    border-radius: 6px;
    object-fit: cover;
}
.icon-24px {
    width: 24px;
    height: 24px;
}
.icon-20px {
    width: 20px;
    height: 20px;
}

.icon-18px {
    width: 18px;
    height: 18px;
}

.icon-15px {
    width: 15px;
    height: 15px;
}

.icon-14px {
    width: 14px;
    height: 14px;
}

.scrollX {
    overflow-x: scroll;
    width: 100%;
    scrollbar-width: none !important;
    --webkit-scrollbar: none !important;
    --webkit-scrollbar-width: none !important;
}

.scrollX::-webkit-scrollbar {
    display: none !important;
}

.lowercase {
    text-transform: lowercase;
}

.shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
}

.radius-top-none{
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.call-btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #FFE3EB;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.crt-call-btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #b0f5ca;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.lds-dual-ring {
    display: inline-block;
    width: 18px;
    height: 18px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 14px;
    height: 14px;
    /* margin: 8px; */
    border-radius: 50%;
    border: 3px solid gray;
    border-color: gray transparent gray transparent;
    animation : lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.infinite-scroll-component__outerdiv {
    overflow-x: hidden;
    scrollbar-width: none !important;
    --webkit-scrollbar-width: none !important
}

.infinite-scroll-component__outerdiv::-webkit-scrollbar {
 display: none !important;
}

.dropdown-item {
    background-color: #fff !important;
    color: var(--prime) !important; 
}

.fs-9 {
    font-size: 9px !important;
}

.logo {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.ecom-logo {
    width: 130px;
}

.w-25px {
    width: 25px;
}

.infinite-scroll-component {
    overflow-x: hidden !important;
}
.error-message {
    color: red;
  }

.copyclipboar-div {
    position: absolute;
    top: 10px;
    left: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.copyclipboarsec-div {
    position: absolute;
    top: 60px;
    left: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.form-check-input-custom {
    color: var(--prime) !important;
    background-color: var(--prime) !important;
}


.dashboard-app-btns {
    display: flex;
    flex-wrap: wrap;
}

.dashboard-app-btns .dashboard-app-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    text-align: center;
    padding: 5px 2px;
    text-decoration: none;
    box-shadow: 0px 1px 2px #d0d0d0;
    border: 1px solid #f1f1f1;
    width: 100%;
    height: 100%;
    position: relative;
}

.dashboard-app-btns .dashboard-app-btn-col {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 3px;
}

.dashboard-app-btns .dashboard-app-btn.food-btn img {
    max-height: 57px;
}

.dashboard-app-btns .dashboard-app-btn img {
    max-height: 40px;
}

.dashboard-app-btns .dashboard-app-btn .dashboard-app-btn-title {
    font-size: 12px !important;
    font-weight: 500;
    text-transform: none;
    font-family: "Roboto-Medium";
    color: #343a40 !important;
}

.over-img-close {
    position: absolute;
    top: 10px;
    right: 8px;
    background-color: #ffe7ee;
    width: 20px;
    height: 20px;
    border-radius: 5px;
}

/* footer bottom sheet start*/

.main-hr-drawer{
    margin: 0 auto;
    /* height: 6px !important; */
    height: 3px !important;
    background: #c0c0c0;
    /* width: 36px; */
    width: 50px;
    border-radius: 4px;
}

.dashboard-app-btns {
    display: flex;
    flex-wrap: wrap;
}

.dashboard-app-btns .dashboard-app-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    text-align: center;
    padding: 5px 2px;
    text-decoration: none;
    box-shadow: 0px 1px 2px #d0d0d0;
    border: 1px solid #f1f1f1;
    width: 100%;
    height: 100%;
    position: relative;
}

.dashboard-app-btns .dashboard-app-btn-col {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 3px;
}

.dashboard-app-btns .dashboard-app-btn.food-btn img {
    max-height: 57px;
}

.dashboard-app-btns .dashboard-app-btn img {
    max-height: 40px;
}

.dashboard-app-btns .dashboard-app-btn .dashboard-app-btn-title {
    font-size: 12px !important;
    font-weight: 500;
    text-transform: none;
    font-family: "Roboto-Medium";
    color: #343a40 !important;
}

.beta-badge {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: #007bff;
    color: #fff;
    font-size: 10px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 1px;
    border-radius: 5px;
}


.btn-menu-back {
    border-radius: 50%;
    height: 35px;
    /* width: 35px; */
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
    padding-left: 10px;
    color: #343a40 !important;
    font-size: 12px;
}

.superapp_menu_tabs {
    border-top: 1px solid #f1f1f1;
}

.footer-bottomsheet {
    /* z-index:999 !important; */
    /* position: absolute !important; */
    padding: 5px !important;
}

.footer-back {
    height: 40px !important;
    padding: 5px !important;
    position: fixed;
    bottom: 48px;
    left: 0;
    width: 100%;
}

/* footer bottom sheet end*/


.text-dark {
    color: #000 !important;
    font-weight: 600 !important;

}

.no-data-img {
    width: 130px;
    height: 130px;
}

.h-100 {
    height: 100%;
}

.map_class {
    border: none !important;
}
.cat-child { 
    margin-left: 20px !important;
}
.timeline-1 .timeline-dot{
    width: 20px;
    height: 20px;
    background-color: #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}
.timeline-1 .timeline-dot.active{
    background-color: #0057ff;
}
.timeline-1 .timeline-line-container{
    width: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.timeline-1 .timeline-line{
    height: 30px;
    width: 3px;
    background-color: #cccccc;
}
.timeline-1 .timeline-line.active{
    background-color: #0057ff;
}
.hrz-line .timeline-line{
    height: 3px;
    width: 100%;
}
.hrz-line .timeline-line-container{
    width: 42%;
}
.badge-2{
    text-transform: uppercase;
    font-size: 12px;
    background: #d7f1ff;
    padding: 1px 5px 2px 5px;
    border-radius: 3px;
    font-weight: 500;
    color: #909191;
}
.legendLeft {
    position: absolute;
    top: -10px;
    width: fit-content;
    padding: 2px 8px;
    background-color: #fff;
    font-weight: 500;
    font-size: 14px;
    left: 13px;
    margin-bottom: 0px !important;
    border-radius: 6px;
    letter-spacing: 0.3px;
}
fieldset {
    position: relative;
    border: 1px solid #e4e3e3;
    border-radius: 3px;
    margin-top: 5px;
}
.dotted {
    border: none;
    border-top: 2px dotted #000; /* You can change the color by replacing #000 with your desired color code */
    color: #fff; /* Set the color to match your background color if needed */
    background-color: #fff; /* Set the background color to match your background color if needed */
    height: 1px; /* Adjust the height as needed */
    margin: 10px 0; /* Adjust the margin as needed */
  }
  .type-img {
    width: 30px;
    height: 30px;
}

/* /////////////////////////////20012024 ////////////////////////*/

.swipeable-drawer-container-footer .cupertino-pane-wrapper {
    z-index: 1331;
}

/** Cupertino Panes Css */
.cupertino-pane-wrapper.swipeable-drawer .pane {
    cursor: default !important;
    padding: 0;
}

.swipeable-drawer-content {
    height: 100%;
}

.swipeable-drawer-content.rounded-corner {
    border-radius: 10px;
}
.cupertino-pane-wrapper 
.bar {
    height: 3px;
    width: 50px;
    background: #ccc;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
}

.swipeable-drawer-container-footer .cupertino-pane-wrapper {
    z-index: 1331;
}

.btn-1drawer-content {
    padding-top: 6px;
}

.btn-1drawer-bar {
    margin-bottom: 6px;
}

.bottom-45 {
    bottom: 45px !important;
}

.card_accordian_li {
    list-style-type: none !important;
}

.logo {
    width: 25px;
    height: 25px;
    margin: 5px;
}

/* footer bottom sheet start*/

.main-hr-drawer {
    margin: 0 auto;
    /* height: 6px !important; */
    height: 3px !important;
    background: #c0c0c0;
    /* width: 36px; */
    width: 50px;
    border-radius: 4px;
}

.dashboard-app-btns {
    display: flex;
    flex-wrap: wrap;
}

.dashboard-app-btns .dashboard-app-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    text-align: center;
    padding: 5px 2px;
    text-decoration: none;
    box-shadow: 0px 1px 2px #d0d0d0;
    border: 1px solid #f1f1f1;
    width: 100%;
    height: 100%;
    position: relative;
}

.dashboard-app-btns .dashboard-app-btn-col {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 3px;
}

.dashboard-app-btns .dashboard-app-btn.food-btn img {
    max-height: 57px;
}

.dashboard-app-btns .dashboard-app-btn img {
    max-height: 40px;
}

.dashboard-app-btns .dashboard-app-btn .dashboard-app-btn-title {
    font-size: 12px !important;
    font-weight: 500 !important;
    text-transform: none;
    font-family: "Roboto";
    color: #343a40 !important;
}

.beta-badge {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: #007bff;
    color: #fff;
    font-size: 10px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 1px;
    border-radius: 5px;
}


.btn-menu-back {
    border-radius: 50%;
    height: 35px;
    /* width: 35px; */
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
    padding-left: 10px;
    color: #343a40 !important;
    font-size: 12px;
}

.superapp_menu_tabs {
    border-top: 1px solid #f1f1f1;
}

.footer-bottomsheet {
    /* z-index:999 !important; */
    /* position: absolute !important; */
    padding: 5px !important;
}

.footer-back {
    height: 40px !important;
    padding: 5px !important;
    position: fixed;
    bottom: 45px;
    left: 0;
    width: 100%;
    z-index: 1332;
}

/* footer bottom sheet end*/

.delivery-img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 6px;
}
.full-map-header .map-topbar #map-search {
    padding-left: 45px;
    height: 40px;
    border-radius: 5px;
    border: 0;
    box-shadow: 0 1px 4px -1px rgb(0 0 0 / 30%);
    margin-top: 10px;
}


#map-live-track {
    height: 100vh;
    position: relative;
}
#map-live-track:after {
    width: 22px;
    height: 40px;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -11px;
    /* background: url('https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi_hdpi.png'); */
    background-size: 22px 40px;
    /* Since I used the HiDPI marker version this compensates for the 2x size */
    pointer-events: none;
    /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}
.map-over {
    z-index: 9;
    position: absolute;
    height: auto !important;
    border-radius: 0 !important;
    width: 100%;
}
.sticky-top {
    position: sticky;
    top: 0;
    z-index: 11;
    border-bottom: none !important;
    background-color: #fff;
}
.custom-swipe-shadow {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.hr-drawer {
    width: 10%;
    background-color: #4d4d4d;
    height: 3px !important;
    opacity: 1 !important;
    margin: 0.8rem 0 !important;
    border-radius: 20px !important;
}
.w-14px {
    width: 14px;
    height: 14px;
}
.rct-node-icon,
.rct-icon.rct-icon-folder-open,
.rct-icon.rct-icon-folder-closed {
  display: none;
}
.p-12 {
    padding: 12px;
}
.badge-1.badge-blue{
    background: #3044d5;
}
.badge-1{
    text-transform: uppercase;
    font-size: 12px;
    padding: 1px 7px 2px 7px;
    border-radius: 3px;
    font-weight: 500;
    background: #01a2f5;
    color: #fff;
}
.badge-1.badge-green{
    background: #0a855f;
}
.badge-1.badge-purple{
    background: rgb(235, 62, 235);
}
.badge-1.badge-green-2{
    background: #8fb55f;
}
.badge-1.badge-red{
    background: #cd422a;
}
.badge-1.badge-red-2{
    background: #e20f0f;
}

.loader-spinner {
    border: 2px solid #e5e5e5;
    border-top: 2px solid rgb(0, 87, 255);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
    padding-top: 2px;
    padding-bottom: 2px;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.m-addr-check {
    height: 20px;
    width: 20px;
    border: 2px solid #ccc;
    border-radius: 100%;
} 
.btn-bottom-fixed{
    position: fixed;
    bottom: 46px;
    left: 0;
    width: 100%;
    border-radius: 0;
    padding-top: 10px;
    padding-bottom: 10px;
}
@media(max-width:992px) {

    .search-order{
        width: 40%;
    }
    
    .mobile-filter {
        display: none;
    }
    .right-panel{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100vh - 45px);
        z-index: 100;
    }
    .right-panel-2{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100vh - 45px);
        z-index: 100;
    }
    .triple-layout{
        display: none;
        overflow: auto;
    }
    .seller-choose-modal{
        height: calc(100% - 28px);
    }
}
@media(min-width:992px) {
    .right-panel{
        position: sticky;
        top: 128px;
        height: calc(100vh - 138px);
        z-index: 9;
        overflow-y: auto;
    }
    .right-panel-2{
        position: sticky;
        top: 128px;
        height: calc(100vh - 159px);
        z-index: 9;
        overflow-y: auto;
    }
}

.list-item{
    border-bottom: 1px solid #cccccc;
}
.list-item:hover{
    background-color: #e6eeff;
}

.mh-250{
    min-height: 250px;
}
.br-8{
    border-radius: 8px;
}

.qr-print-area{
    /* size: 12in 18in; */
    /* width: 1152px;
    height: 1728px; */
    width: 3456px;
    height: 5184px;
}
.qr-bg-eng, .qr-bg-tamil{
    /* width: 4in;
    height: 6in; */
    /* width: 384px;
    height: 576px; */
    width: 1152px;
    height: 1728px;
    background-image: url("https://cdn3.1superapp.com/media/92049f6f16daa3d3ee0ab0c8f774bbb4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.qr-bg-tamil{
    background-image: url("https://cdn3.1superapp.com/media/366f687333351751cf3de7aceaf44c96.jpg");
}
.qr-canvas{
    position: absolute;
    bottom: 481px;
    left: 3px;
    width: 100%;
    text-align: center;
}
.qr-bg-tamil .qr-canvas{
    bottom: 435px;
    left: -15px;
}
.qr-data-info-sm{
    position: absolute;
    bottom: 745px;
    left: 798px;
    transform: rotate(270deg);
    color: #ffffff;
    font-size: 19px;
}
.qr-bg-tamil .qr-data-info-sm{
    left: 765px;
    bottom: 710px;
}

.btn-choose{
    display: flex;
    justify-content: center;
}
.search-order{
    width: 48%;
}
.map-marker {
    background-color: #4285F4;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 11px;
    padding: 5px 5px;
    position: relative;
    max-width: 100px;
    text-align: center;
  
  }
  
  /* .price-tag::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #4285F4;
  } */

  /* Switch
.pure-material-switch {
    z-index: 0;
    position: relative;
    display: inline-block;
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
    font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
    font-size: 16px;
    line-height: 1.5;
    width: max-content;
}

/* Input */
/* .pure-material-switch > input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    right: 10px;
    top: -2px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
   
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
} 

.pure-material-switch {
    color: rgba(var(--pure-material-onsurface-rgb,0,0,0),.87);
    display: inline-block;
    font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
    font-size: 16px;
    position: relative;
    width: max-content;
    z-index: 0;
}

.pure-material-switch input {
    display: none;
}

.pure-material-switch span {
    display: inline-block;
    width: 34px;
    height: 20px;
    background-color: #ccc;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}

.pure-material-switch span::after {
    content: '';
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: 0.2s;
}

.pure-material-switch input:checked + span {
    background-color: #4caf50;
}

.pure-material-switch input:checked + span::after {
    left: 16px;
} */



/* Switch */
.pure-material-switch {
    z-index: 0;
    position: relative;
    display: inline-block;
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
    font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
    font-size: 16px;
    line-height: 1.5;
    width: max-content;
}

/* Input */
.pure-material-switch > input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    right: 10px;
    top: -2px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    /* background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38); */
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
}

/* Span */
.pure-material-switch > span {
    display: inline-block;
    width: 100%;
    cursor: pointer;
}

/* Track */
.pure-material-switch > span::before {
    content: "";
    float: left;
    display: inline-block;
    margin: 5px 0 5px 10px;
    border-radius: 7px;
    width: 36px;
    height: 14px;
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    vertical-align: top;
    transition: background-color 0.2s, opacity 0.2s;
}

/* Thumb */
.pure-material-switch > span::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition: background-color 0.2s, transform 0.2s;
}

/* Checked */
.pure-material-switch > input:checked {
    left: 21px;
    background-color: rgb(var(--pure-material-primary-rgb, 0, 87, 255));
}

.pure-material-switch > input:checked + span::before {
    background-color: rgba(var(--pure-material-primary-rgb, 0, 87, 255), 0.6);
}

.pure-material-switch > input:checked + span::after {
    background-color: rgb(var(--pure-material-primary-rgb, 0, 87, 255));
    transform: translateX(16px);
}

/* Hover, Focus */
.pure-material-switch:hover > input {
    opacity: 0.04;
}

.pure-material-switch > input:focus {
    opacity: 0.12;
}

.pure-material-switch:hover > input:focus {
    opacity: 0.16;
}

/* Active */
.pure-material-switch > input:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
}

.pure-material-switch > input:active + span::before {
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.6);
}

.pure-material-switch > input:checked:active + span::before {
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}

/* Disabled */
.pure-material-switch > input:disabled {
    opacity: 0;
}

.pure-material-switch > input:disabled + span {
    color: rgb(var(--pure-material-onsurface-rgb, 0, 0, 0));
    opacity: 0.38;
    cursor: default;
}

.pure-material-switch > input:disabled + span::before {
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}

.pure-material-switch > input:checked:disabled + span::before {
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.6);
}
.badge-online{
    display: inline-block;
    background-color: #006800;
    color: #ffffff;
    border-radius: 10px;
    padding: 2px 8px;
    font-size: 12px;
}
.badge-offline{
    display: inline-block;
    background-color: #989797;
    color: #ffffff;
    border-radius: 10px;
    padding: 2px 8px;
    font-size: 12px;
}
.out_of_stock_swipe input[type="radio"]  p {
    color: #222;
    text-align: center;
}


.out_of_stock_swipe input[type="radio"]{
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.out_of_stock_swipe input[type="radio"]:checked + p{
    color: var(--color1) !important;
}

.out_of_stock_swipe label {
    border: 1px solid #a2a2a2;
    width: fit-content;
    /* height: 30px; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 10px;
    margin: 0 10px 10px 0;
}

.out_of_stock_swipe input[type="radio"]:checked + label {
    border-color: var(--color1) !important;
}
.out_of_stock_swipe .out_of_stock_swipe_label:has(> input[type="radio"]:checked) {
    border-color: var(--color1) !important;
}
.cursor:hover {
    background-color: #f0f0f0; /* Light gray background on hover */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow on hover */
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition */
  }

  .radio-border{
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 10px;
  }
  .radio-border .md-radio label{
    width: 100%;
  }
  
  .md-radio.md-radio-inline {
    display: inline-block;
  }
  
  .md-radio input[type="radio"] {
    display: none;
  }
  
  .md-radio input[type="radio"]:checked+label:before {
    border-color: #007bff;
    animation: rippleRadio 0.2s linear forwards;
  }
  
  .md-radio input[type="radio"]:checked+label:after {
    transform: scale(1);
  }
  
  .md-radio label {
    display: inline-block;
    min-height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
  }
  
  .md-radio label:before,
  .md-radio label:after {
    position: absolute;
    content: "";
    border-radius: 50%;
    transition: all 0.3s ease;
    transition-property: transform, border-color;
  }
  
  .md-radio label:before {
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(218, 218, 218, 0.8);
  }
  
  .md-radio label:after {
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    transform: scale(0);
    background: #007bff;
  }
  .slider-container {
    position: relative;
    width: 300px;
    margin: 50px auto;
    text-align: center;
  }
  
  .tooltip {
    position: absolute;
    top: -35px;
    background: black;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    transition: opacity 0.2s ease-in-out, left 0.1s linear;
    opacity: 0;
    transform: translateX(-50%);
  }
  
  .tooltip.visible {
    opacity: 1;
  }
  
  .slider {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    background: #ddd;
    height: 6px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
  }
  
  /* Custom Thumb (for Chrome, Safari) */
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: blue;
    border-radius: 50%;
    cursor: pointer;
  }
  
  /* Custom Thumb (for Firefox) */
  .slider::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: blue;
    border-radius: 50%;
    cursor: pointer;
  }